<template>
	<div :class="themeClass" class="min-width1100 max-width1100 margin-lr-auto" style="width: 1110px;margin-top: 20px;background-color: #FFFFFF;">
		<!-- 面包屑 -->
		<breadcrumb></breadcrumb>
		<!-- 面包屑END -->

		<div class="">
			<div>

				<div class="border-F2F2F2">
					<!-- 收货人信息 -->
					<div class="flex-row-space-between-center padding-lr-15 padding-t-10">
						<div class="font-size14 font-weight700">收货人信息</div>

					</div>
					<div>

						<!-- 上面这部分用来显示被选中的数据,下面的位其余数据 -->
						<div class="price-box active flex-row-space-between-center box-sizing pointer-border-color-theme border-F8F9FE"
						 style="background: #f8f9fe;">
							<div class="font-size14  flex-row-align-center font-color-999">
								<div class=" flex-column-align-center margin-l-10 font-color-999">
									<div class="">{{ item.fConsignee }}</div>
									<div class="">{{ item.fConsigneePhone }}</div>
								</div>
								<div class="margin-l-15 font-color-999">收货地址：{{ item.fDeliveryAddress }}</div>
							</div>

						</div>
					</div>
					<!-- 收货人信息结束 -->

					<div class="flex-row-space-between-center font-size14 padding-lr-10 order-title-box margin-t-10 margin-b-5">
						<div class="flex-row-center-center order-title55">
							商品
						</div>
						<div class="flex-row-space-between-center order-title45">

							<div class="flex-row-center-center order-title50">数量</div>
							<div class="flex-row-center-center order-title50">价格</div>

						</div>
					</div>
					<div class="">
						<div class="flex-row-space-between-center padding-lr-10 list-title-box">
							<div class="flex-row-align-center font-size14 font-color-000">
								<div class="">{{ item.fCreateTime }}</div>
								<div class="flex-row-align-center margin-l-19">
									<div class="margin-r-5">订单编号:</div>
									<div class="color-theme">{{ item.fOrderNumber }}</div>
								</div>
							</div>

						</div>
						<div class="flex-row-space-between-center shopCart padding-lr-10 bg-fff">
							<div class="flex-row-start-center order-title55" style="height:100px;">
								<div class="flex-row-space-between-center">
									<div class="box-shadow-all imageRadius">

										<imageBox :width='65' :height='65' :borderRidus='10' :src="item.fPicturePath"></imageBox>
									</div>
								</div>
								<div class="margin-l-19 font-color-000">{{ item.fGoodsFullName }}</div>
							</div>
							<div class="flex-row-align-center order-title45 font-size14" style="height: 65px;">
								<div class="flex-row-center-center order-title50 border-left80  font-color-333">
									x
									<div class="margin-l-3">{{item.fPlanAmount}}</div>
								</div>
								<div class="flex-row-center-center order-title50 border-left80 font-color-000">
									{{ item.fGoldCoinPrice }}金币
								</div>

							</div>
						</div>
					</div>

					<div class="flex-row-right bottomBox font-size16" style="line-height: 30px;">
						<div class="flex-row-center-center" style="margin-right: 30px;">
							<div class="flex-row-align-center">
								<div class="flex-row margin-r-10">

									<p class="font-color-000">实付：</p>
									<p class="font-color-FF0000 font-weight700">{{item.fUseGoldCoin}}金币</p>
								</div>
								<div v-if="item.fShipUrl != null" class="padding-lr-10 pointer border-color-theme color-theme font-size13">
									查看物流信息
								</div>

							</div>
						</div>
						<!-- </div> -->
					</div>
				</div>
			</div>
		</div>
		<!-- 收货地址 -->

	</div>
</template>

<script>
	import imageBox from '@/components/imageBox/imageBox.vue'
	import {
		mapGetters
	} from 'vuex';
	import breadcrumb from '@/components/breadcrumb.vue';
	import selectArea from '@/components/selectArea/selectArea.vue';
	export default {
		components: {
			breadcrumb,
			selectArea,
			imageBox
		},
		data() {

			return {
				addressitem: {},
				item: {},
			};
		},
		async mounted() {
			this.getItem();
		},

		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			// 去除斜杠
			geshihua(address){
				return  address.replace(/[\\]/g,' ');
			},
			
			getItem() {
				this.ApiRequestPostNOMess("api/mall/ebsale/order-gold-coin/get", {
					id: this.$route.query.id
				}).then(res => {
					this.item = {};
					this.item = res.obj;
					this.item.fCreateTime = this.getTime(this.item.fCreateTime);
					this.item.fDeliveryAddress =  this.geshihua(this.item.fDeliveryAddress);
				}).catch(err => {
					this.item = {};
				})
			},

		}
	};
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-color-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.pointer-border-color-theme:hover {
		// cursor: pointer;


	}

	.pointer-color-theme:hover {
		cursor: pointer;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	/* 面包屑CSS */
	// .el-tabs--card .el-tabs__header {
	// 	border-bottom: none;
	// }
	.breadcrumb {
		margin-top: 15px;
		background-color: white;
	}

	// .breadcrumb .el-breadcrumb {
	// 	width: 1225px;
	// 	line-height: 30px;
	// 	font-size: 16px;
	// 	margin: 0 auto;
	// }
	/* 面包屑CSS END */

	::v-deep .el-checkbox {
		padding: 0 10px !important;
	}

	// 购物车
	.wrap {
		width: 100%;
		// margin-bottom: 40px;
		// margin-top: 9px;
	}

	// 下方按钮
	/* 新的购物车样式 */

	.tag-box-red {
		// margin: 0px !important;
		margin-right: 8px;
		padding: 0 4px;
		// height: 40px;
		line-height: 18px;
		color: #ffffff;
		background-image: linear-gradient(to right, #ff0000, #ff9999);
		// border-radius: 4px;
		font-weight: 400;
	}

	.tag-box-blue {
		// margin: 0px !important;
		padding: 0 5px;
		// height: 19px;
		line-height: 18px;
		color: #ffffff;
		font-weight: 400;
	}

	.margin-l-17 {
		margin-left: 17px;
	}

	.imageRadius {
		width: 65px;
		height: 65px;
		border-radius: 10px;
	}

	.width756 {
		width: 756px;
	}

	.width156 {
		width: 156px;
	}

	.bottomBox {
		height: 80px;
		background: #f8f9fe;
		width: 100%;
	}

	.flexUD {
		display: flex;
		//align-items: center; /*垂直居中*/
		justify-content: center;
		/*水平居中*/
	}

	/* 单选地址 */
	.price-box.active {
		width: 1070px;
		height: 50px;

		margin: 15px;
		position: relative;

	}

	.price-box.none {
		width: 1070px;
		height: 50px;
		margin: 15px;
		position: relative;

	}

	/* 步骤条 */
	::v-deep .el-step__line-inner {
		height: 10px;
		background-color: #00cc88;
	}

	::v-deep .el-step__icon.is-text {
		background: #00cc88;
		color: #fff;
		border: 5px solid;
	}

	::v-deep .el-step__icon {
		height: 50px;
		width: 50px;
	}

	::v-deep .el-step.is-horizontal .el-step__line {
		height: 10px;
		background: #e4e4e4;
		margin-top: 8px;
	}

	::v-deep .is-success {
		border-color: #dffff5;
		color: #00cc88;

		.el-step__icon.is-text {
			background: #00cc88;
			color: #dffff5;
		}
	}

	::v-deep .is-process {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .is-wait {
		border-color: #fafafa;
		color: #999;

		.el-step__icon.is-text {
			background: #e4e4e4;
			color: #fafafa;
		}
	}

	::v-deep .el-step__title.is-process {
		font-weight: 400;
		// color: #999999;
	}

	::v-deep .el-step__title.is-wait {
		font-weight: 400;
		// color: #999999;
	}

	/* 步骤条结束 */

	.margin-l-15 {
		margin-left: 15px;
	}

	.margin-lr-15 {
		margin-left: 15px;
		margin-right: 15px;
	}

	/* 选择框 */
	::v-deep .el-checkbox {
		padding: 0 15px !important;
	}

	/* 下拉 */
	::v-deep .border-top-F2F2F2 .el-input__inner {
		border-radius: 0;
		width: 196px;
		// height: 32px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-dialog .el-input__inner {
		width: 320px;
	}

	.inputStyle {
		border-radius: 0;
		width: 196px;
		height: 28px;
		font-size: 14px;
		text-align: center;

		@include themify($themes) {
			color: themed('themes_color');
		}

		border: 1px solid #f2f2f2;
	}

	/* 承运商 */
	.carrier {
		// width: 100%;
	}

	::v-deep .carrier .el-input__inner {
		width: 255px;
	}

	::v-deep .invoice .el-input__inner {
		width: 321px;
	}

	::v-deep .el-cascader {
		width: 321px;
	}

	/* 虚线 */
	.divcss5-3 {
		// border-top: 2px dashed #f2f2f2;
		// height: 50px;
		width: 100%;
		margin-bottom: 10px;
	}

	.hover:hover {
		text-decoration: underline;

		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
	.el-checkbox__input.is-indeterminate .el-checkbox__inner {
		@include themify($themes) {
			background-color: themed('themes_color');
			border-color: themed('themes_color');
		}
	}

	::v-deep .el-dialog__body {
		padding-top: 5px;
	}

	.order-title-box {
		height: 35px;
		background-color: #F2F4FD;
	}

	.order-title-box {
		height: 35px;
		background-color: #F2F4FD;
	}

	.order-title55 {
		width: 55%;
	}

	.order-title45 {
		width: 45%;
	}

	.order-title50 {
		width: 50%;
	}

	.list-title-box {
		height: 35px;
		background: #F2F4FD;
	}
</style>
